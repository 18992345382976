export default {
  headerTitle: 'Rollee Ride',
  footerTitle: 'Powered by',
  homePageTitle: 'Let\'s link your work accounts',
  homeGetStarted: 'Get started',
  termsPageTitle: 'We need your permission to retrieve your data',
  termsDetailsHowItWorks: 'What is Rollee',
  termsDetailsHowItWorksDescription: 'With your permission, Rollee can retrieve your account data for you in order to provide you with your requested service.',
  termsDetailsDataProtection: 'How is my data protected',
  termsDetailsDataProtectionDescription: 'Your data is encrypted. You can ask Rollee to stop accessing your data at anytime.',
  termsFooterToggleAccept: 'I have read and accepted Rollee\'s',
  termsFooterToggleAcceptLink: 'Terms of Use',
  termsFooterAllow: 'Allow',
  termsPrivacyPolicy: 'Privacy Policy',
  termsPrivacyPolicyDescription_1: 'By clicking Allow you are permitting',
  termsPrivacyPolicyDescription_2: 'and Rollee to access your data and accept Rollee\'s',
  connectPageTitle: 'Start by finding your work account',
  inputSearchPlaceholder: 'Search work account',
  logInTo: 'Log in to',
  username: 'Username',
  password: 'Password',
  email: 'Email',
  phone: 'Phone',
  emailPhone: 'Email or Phone',
  defaultLogin: 'Login',
  secureAccess: 'Secure and private access',
  dataProtection: 'Your data is encrypted and never made accessible to anyone without your permission',
  dontSeeCompany: 'Don\'t see your company?',
  clickToContinue: 'Click here to continue',
  successTitle: 'Success!',
  connecting: 'Establishing secure connection',
  pleaseWait: 'On average this takes 30 secs to 1 minute',
  dataSourceError: 'There was an error retrieving your data. Please try again later.',
  goBack: 'Go back!',
  continue: 'Continue',
  sureWantToExit: 'Are you sure you want to exit?',
  progressWillBeLost: 'Your progress will be lost',
  yesExit: 'Yes, exit',
  noContinue: 'No, continue',
  connectedAccounts: 'Connected accounts',
  suggested: 'Suggested',
  addAccount: 'Add account',
  done: 'Done',
  tryAgain: 'Try again',
  submit: 'Submit',
  getBackYouSoon: 'Thank you. Get back to you soon!',
  enterCompanyName: 'Enter company name',
  errorConnectingDataSource: 'Connection error. Please try again later.',
  termsOfUseUrl: 'https://www.getrollee.com/terms-of-service-api',
  privacyPolicyUrl: 'https://www.getrollee.com/privacy-policy-connect-api',
  searchCountriesPlaceholder: 'Search country or code',
  codePlaceholder: 'Enter code',
  code: 'Please enter code',
  send: 'Send',
  logInWithFacebook: 'Continue with Facebook',
  facebookNotSupported: 'Sorry, we don\'t support Facebook Connect yet.',
  connectToOtherAccounts: 'You can connect your other driver accounts.',
  backToSearch: 'Back to Search',
  close: 'Close',
  errors: {
    'credentials-password': 'Invalid password.',
    'credentials-code': 'Invalid verification code.',
    'invalid-phone': 'Invalid phone number.',
    credentials: 'Invalid credentials.',
    'credentials-email': 'Invalid credentials.',
    'account-disabled': 'Account disabled.',
    'credentials-phone': 'We cannot recognize this number. Please check your number or use email.',
  },
  waitMessages: [
    'Each day will be better than the last. This one especially',
    'Are we there yet!',
    'I swear it\'s almost done',
    'Let\'s take a mindfulness minute',
    'Twiddling thumbs..',
    'Let\'s hope it\'s all worth the wait',
    '\'Don\'t sweat the petty things and don\'t pet the sweaty things\' - Carlin',
    '\'Electricity is just organized lighting\' - Carlin',
    'Always do whatever is next',
    'Generating witty dialog..',
    'Bending the spoon..',
    'At least you\'re not on hold',
    'Swapping time and space',
    'Satellite almost in position',
    'Doing science..',
  ],
};
